import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteReport,
  getActionTypes,
  getAllActionTypes,
  getChangesInProperties,
  getDispatchedFires,
  getHomeStatusByFires,
  getMap,
  getMonitoredFire,
  getProgramFiresCount,
  getPropertiesCurrent,
  getReport,
  getReports,
  getThreatenedProperties,
  getVisitsByFire,
  postReport,
} from "../../api/programUseAPI";
import {
  IReportQuery,
  ICardData,
  IFireChartData,
  IDispatchFireData,
  IChangeInProperties,
  IStateChartData,
  IExcel,
  IActionType,
} from "../../api/models";
import { deepCopy, postDownload } from "utils";
import moment from "moment";
import { ICurrentProperties } from "api/models/programUse/ICurrentProperties";

//define the state for Fire Overview
export interface ProgramUseState {
  loaded: boolean;
  expandedTabs: string[],
  postQuery?: IReportQuery;
  selectedQuery: IReportQuery;
  allActionTypes?: IActionType[];
  queries: IReportQuery[];
  programFireTiles?: ICardData[];
  threatenedProperties?: IFireChartData[];
  dispatchedFiresData?: IDispatchFireData[];
  propertyCurrent?: ICurrentProperties[];
  propertyChangesData?: IChangeInProperties[];
  stateChartData?: IStateChartData[];
  firesInStates?: IStateChartData[];
  visitsByFire?: IFireChartData[];
  actionTypes?: ICardData[];
  homeStatusByFire?: IFireChartData[];
  map?: any;
  chartThreatenedProperties?: string,
  chartDispatchedFires?: string,
  chartPropertyChanges?: string,
  chartMap?: string,
  chartProximity?: string,
  chartVisitsByFire?: string,
  chartActionsByFires?: string,
  chartHomeStatusByFires?: string,
}

const initialState: ProgramUseState = {
  loaded: false,
  selectedQuery: { queryId: 0, queryName: "" },
  expandedTabs: ['programFires'],
  queries: [],
};

export const fetchPropertyCurrent = createAsyncThunk(
  "actions/fetchPropertyCurrent",
  async (params: IReportQuery) => {
    let response = await getPropertiesCurrent(params);
    return response || [];
  }
);

export const fetchChangesInProperties = createAsyncThunk(
  "actions/fetchChangesInProperties",
  async (params: IReportQuery) => {
    let response = await getChangesInProperties(params);
    return response || [];
  }
);

export const fetchProgramFiresCount = createAsyncThunk(
  "actions/fetchProgramFiresCount",
  async (params: IReportQuery) => {
    let response = await getProgramFiresCount(params);
    return response || [];
  }
);

export const fetchDispatchedFires = createAsyncThunk(
  "actions/fetchDispatchedFires",
  async (params: IReportQuery) => {
    let response = await getDispatchedFires(params);
    return response || [];
  }
);

export const fetchThreatenedProperties = createAsyncThunk(
  "actions/fetchThreatenedProperties",
  async (params: IReportQuery) => {
    let response = await getThreatenedProperties(params);
    return response || [];
  }
);

export const fetchMonitoredFire = createAsyncThunk(
  "actions/fetchMonitoredFire",
  async (params: IReportQuery) => {
    let response = await getMonitoredFire(params);
    return response || [];
  }
);

export const fetchMap = createAsyncThunk("actions/fetchMap", async () => {
  return getMap();
});

export const fetchReports = createAsyncThunk(
  "actions/fetchReports",
  async () => {
    let response = await getReports();
    return response || [];
  }
);

export const saveReport = createAsyncThunk(
  "actions/saveReport",
  async (params: IReportQuery) => {
    let response = await postReport(params);
    return response || 0;
  }
);

export const dropReport = createAsyncThunk(
  "actions/dropReport",
  async (id: number) => {
    let response = await deleteReport(id);
    return response ? id : 0;
  }
);

export const fetchVisitsByFire = createAsyncThunk(
  "actions/fetchVisitsByFire",
  async (params: IReportQuery) => {
    let response = await getVisitsByFire(params);
    return response || [];
  }
);

export const fetchActionTypesOfFires = createAsyncThunk(
  "actions/fetchActionTypesOfFires",
  async (params: IReportQuery) => {
    let response = await getActionTypes(params);
    return response || [];
  }
);

export const fetchHomeStatusByFires = createAsyncThunk(
  "actions/fetchHomeStatusByFires",
  async (params: IReportQuery) => {
    let response = await getHomeStatusByFires(params);
    return response || [];
  }
);

export const fetchReport = createAsyncThunk(
  "actions/fetchReport",
  async (id: string | number) => {
    return getReport(id);
  }
);

export const fetchAllActionTypes = createAsyncThunk(
  "actions/fetchAllActionTypes",
  async () => {
    return getAllActionTypes();
  }
);

/** State slice with reducers, link reducer and action */
export const programUseSlice = createSlice({
  name: "programUse",
  initialState,
  reducers: {
    setSelectedReport: (state, action) => {
      state.selectedQuery = action.payload;
    },
    resetReport: (state, action) => {
      state.loaded = !!action.payload;
      state.expandedTabs = ['programFires']
      state.postQuery = deepCopy(state.selectedQuery)
      state.programFireTiles = [];
      state.threatenedProperties = [];
      state.dispatchedFiresData = [];
      state.propertyCurrent = [];
      state.propertyChangesData = [];
      state.stateChartData = [];
      state.firesInStates = [];
      state.visitsByFire = [];
      state.actionTypes = [];
      state.homeStatusByFire = [];
    },
    exportExcel(state, action) {
      const type: string = action.payload.type;
      const fireName = `${action.payload.title}.xlsx`;
      const excelData: IExcel = {
        name: fireName,
        sheets: [],
      };
      switch (type) {
        case "programFires":
          excelData.sheets.push({
            name: 'Program Fires',
            data: [['Type', 'Count'], ...(state.programFireTiles || []).map((s) => [s.type, s.count.toString()])]
          }, {
            name: 'Potentially Threatened',
            data: [['Fire Name', 'Properties'], ...(state.threatenedProperties || []).map((s) => [s.fire_name, s.count.toString()])]
          }, {
            name: 'Dispatch Fires',
            data: [['Fire Name', 'Status', 'From', 'To'], ...(state.dispatchedFiresData || []).map((s) => [
              s.fire_name,
              s.type,
              moment(s.from).format("YYYY-MM-DD"),
              moment(s.to).add(-1, "days").format("YYYY-MM-DD")
            ])]
          })
          break;
        case "monitoredFires":
          excelData.sheets.push({
            name: 'Monitored Fires',
            data: [['State', 'Count'], ...(state.stateChartData || []).map((s) => [s.state, s.count.toString()])]
          }, {
            name: 'Dispatch Fires',
            data: [['State', 'Type', 'Count'], ...(state.firesInStates || []).map((s) => [s.state, s.type || '', s.count.toString()])]
          })
          break;
        case "visit":
          excelData.sheets.push({
            name: 'Visits by Fire',
            data: [['Fire Name', 'Type', 'Count'], ...(state.visitsByFire || []).map((s) => [s.fire_name, s.type || '', s.count.toString()])]
          }, {
            name: 'Actions Taken by Type',
            data: [['Action', 'Count'], ...(state.actionTypes || []).map((s) => [s.type, s.count.toString()])]
          }, {
            name: 'Last Home Status by Fire',
            data: [['Fire Name', 'Status', 'Count'], ...(state.homeStatusByFire || []).map((s) => [s.fire_name, s.type || '', s.count.toString()])]
          })
          break;
        default:
          return;
      }
      console.log(excelData);
      postDownload("/api/parseExcel", fireName, excelData);
    },
    toggleTab(state, action) {
      const key = action.payload;
      const index = state.expandedTabs.indexOf(key);
      if (index >= 0) {
        state.expandedTabs.splice(index, 1);
      } else {
        state.expandedTabs.push(key);
      }
      state.expandedTabs = [...state.expandedTabs];
    },
    updateChart(state, action) {
      const { key, image }: { key: string; image: string } = action.payload;
      switch (key) {
        case 'chartThreatenedProperties':
          state.chartThreatenedProperties = image;
          break;
        case 'chartDispatchedFires':
          state.chartDispatchedFires = image;
          break;
        case 'chartPropertyChanges':
          state.chartPropertyChanges = image;
          break;
        case 'chartMap':
          state.chartMap = image;
          break;
        case 'chartProximity':
          state.chartProximity = image;
          break;
        case 'chartVisitsByFire':
          state.chartVisitsByFire = image;
          break;
        case 'chartActionsByFires':
          state.chartActionsByFires = image;
          break;
        case 'chartHomeStatusByFires':
          state.chartHomeStatusByFires = image;
          break;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPropertyCurrent.fulfilled, (state, action) => {
      state.propertyCurrent = action.payload;
    });
    builder.addCase(fetchChangesInProperties.fulfilled, (state, action) => {
      state.propertyChangesData = action.payload;
    });
    builder.addCase(fetchProgramFiresCount.fulfilled, (state, action) => {
      state.programFireTiles = [
        ...(state.programFireTiles || []),
        ...action.payload,
      ];
    });
    builder.addCase(fetchDispatchedFires.fulfilled, (state, action) => {
      state.dispatchedFiresData = action.payload;
    });
    builder.addCase(fetchThreatenedProperties.fulfilled, (state, action) => {
      state.threatenedProperties = action.payload;
      let total = 0;
      state.threatenedProperties?.forEach((s) => (total += s.count));
      state.programFireTiles = [
        ...(state.programFireTiles || []),
        { type: "Potentially Threatened on Dispatch Fires", count: total },
      ];
    });
    builder.addCase(fetchMap.fulfilled, (state, action) => {
      state.map = action.payload;
    });
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      state.queries = action.payload;
    });
    builder.addCase(saveReport.fulfilled, (state, action) => {
      if (state.selectedQuery?.queryId) {
        for (var i = 0; i < state.queries?.length; i++) {
          const query = state.queries[i];
          if (query.queryId === state.selectedQuery.queryId) {
            state.queries.splice(i, 1, deepCopy(action.meta.arg));
            break;
          }
        }
      } else {
        let query = deepCopy(action.meta.arg);
        state.selectedQuery.queryId = action.payload;
        query.queryId = action.payload;
        state.queries.push(query);
      }
    });
    builder.addCase(dropReport.fulfilled, (state, action) => {
      if (action.payload > 0) {
        if (
          state.selectedQuery &&
          state.selectedQuery.queryId === action.payload
        ) {
          state.selectedQuery = {
            actionIds: [],
            clientIds: [],
            from: "",
            to: "",
            lastPropertyStatus: [],
            propertyTypeIds: [],
            states: [],
          };
        }
        for (var i = 0; i < state.queries.length; i++) {
          const { queryId } = state.queries[i];
          if (queryId === action.payload) {
            state.queries.splice(i, 1);
            break;
          }
        }
      }
    });
    builder.addCase(fetchMonitoredFire.fulfilled, (state, action) => {
      state.firesInStates = action.payload;
      state.stateChartData = action.payload.filter(
        (s) => s.type === "Fires Monitored"
      );
    });
    builder.addCase(fetchActionTypesOfFires.fulfilled, (state, action) => {
      state.actionTypes = action.payload;
    })
    builder.addCase(fetchHomeStatusByFires.fulfilled, (state, action) => {
      state.homeStatusByFire = action.payload;
    })
    builder.addCase(fetchVisitsByFire.fulfilled, (state, action) => {
      state.visitsByFire = action.payload;
    })
    builder.addCase(fetchReport.fulfilled, (state, action) => {
      delete action.payload.queryId;
      state.selectedQuery = action.payload;
    })
    builder.addCase(fetchAllActionTypes.fulfilled, (state, action) => {
      state.allActionTypes = action.payload;
    })
  },
});

/** Action creators are generated for each case reducer function */
export const { setSelectedReport, resetReport, exportExcel, toggleTab, updateChart } =
  programUseSlice.actions;

export default programUseSlice.reducer;
